<template>
  <div id="app">
    <AppHeader />
    <router-view />
    <AppFooter v-show="isLoading" />
  </div>
</template>

<script>
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'

export default {
  name: 'App',
  components: {
    AppFooter,
    AppHeader,
  },
  computed: {
    isLoading() {
      return this.$route.meta.isLoading
    },
  },
}
</script>

<style>
@import '@/assets/css/style.css';
</style>
