export const API_ENDPOINTS = {
  contactUs: '/contact-us-request/',
  blogs: '/blogs',
  featuredBlogs: 'blogs/featured/',
  dataDeletion: '/user/data-deletion-request/',
  generateOtp: '/user/generate-otp/',
  verifyOtp: '/user/verify-otp/',
}

export const USERS_COUNT = 43000

export const PLAY_STORE_DOWNLOAD_LINK =
  'https://play.google.com/store/apps/details?id=com.boomm.app'

export const BLOG_CATEGORY_ALL = 'all'
