<template>
  <div id="quick-links-section" class="links-section">
    <div class="links-title">Quick Links</div>
    <div class="links-column">
      <router-link :to="{ name: 'aboutUs' }"> About Us </router-link>
      <router-link :to="{ name: 'blogsList' }"> Blogs </router-link>
      <a
        class="link"
        :href="playStoreDownloadLink"
        rel="noreferrer"
        target="_blank"
        >Download Now</a
      >
      <router-link :to="{ name: 'InvestmentCalculator' }"> Investment Calculator </router-link>
    </div>
  </div>
  <div id="company-section" class="links-section">
    <div class="links-title">Company</div>
    <div class="links-column">
      <router-link :to="{ name: 'termsConditions' }">
        Terms & Conditions
      </router-link>
      <router-link :to="{ name: 'privacyPolicy' }">
        Privacy Policy
      </router-link>
    </div>
  </div>
  <div id="customer-support-section" class="links-section">
    <div class="links-title">Customer Support</div>
    <div class="links-column">
      <router-link :to="{ name: 'getInTouch' }"> Get In Touch </router-link>
      <a
        class="link"
        href="mailto:team@boomm.in"
        rel="noreferrer"
        target="_blank"
      >
        Write to Us
      </a>
    </div>
  </div>
</template>

<script>
import { PLAY_STORE_DOWNLOAD_LINK } from '@/constants/common'
export default {
  name: 'LinksSection',
  data() {
    return {
      playStoreDownloadLink: PLAY_STORE_DOWNLOAD_LINK,
    }
  },
}
</script>
