<template>
  <div>
    <nav>
      <div class="nav-container">
        <a href="/" class="logo">
          <img :src="heroSectionPaths['primary-logo']" alt="Boomm logo" loading="lazy" />
        </a>
        <div class="toggleMenu" :class="{ 'open': isMenuOpen }" @click="toggleMenu">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="nav-links" :class="{ 'show-menu': isMenuOpen }">
          <a href="/blogs">Blogs</a>
          <a href="/get-in-touch">Get In Touch</a>
          <button type="button" @click="openPlayStoreLink">Download Now</button>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { PLAY_STORE_DOWNLOAD_LINK } from '@/constants/common'

export default {
  name: 'HeaderPage',
  data() {
    return {
      playStoreDownloadLink: PLAY_STORE_DOWNLOAD_LINK,
      isMenuOpen: false
    }
  },
  computed: {
    heroSectionPaths() {
      return {
        'primary-logo': require('@/assets/images/logos/primary.svg')
      }
    }
  },
  methods: {
    openPlayStoreLink() {
      window.open(this.playStoreDownloadLink, '_blank')
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    }
  }
}
</script>

<style scoped>
nav {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    box-shadow: 0px 0px 30px 0px rgba(113, 97, 239, 0.15);
    background: var(--white);
    padding: 15px 20px;
  }
  
.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
  
.logo img {
    width: 150px;
}
  
.nav-links {
    display: flex;
    align-items: center;
    gap: 40px;
}
  
.nav-links a {
    text-decoration: none;
    font-family: 'Dm Sans';
    color: black;
    font-size: 18px;
    font-weight: 600;
}  

nav>div>button {
    display: flex;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    gap: 10px;
    color: var(--white);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: var(--primary);
    color: var(--white);
    border: none;
    outline: none;
    box-shadow: none;
    margin-top: 25px;
    /* width: 134px;
    height: 38px; */
}

nav > div > a {
    text-decoration: none;
}

nav>img {
    width: 98.209px;
    height: 35px;
}

.toggleMenu {
  display: none;
  width: 30px;
  height: 25px;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.toggleMenu span {
  display: block;
  width: 100%;
  height: 4px;
  background: #333;
  transition: all 0.3s ease-in-out;
}

/* For Mobile Devices */
@media (max-width: 768px) {
  .toggleMenu {
    display: flex;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 20px;
    background: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 8px;
  }

  .show-menu {
    display: flex;
  }
  
  .toggleMenu.open span:nth-child(1) {
    transform: translateY(10px) rotate(45deg);
  }
  
  .toggleMenu.open span:nth-child(2) {
    opacity: 0;
  }
  
  .toggleMenu.open span:nth-child(3) {
    transform: translateY(-10px) rotate(-45deg);
  }

  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 100;
    width: 100%;
    padding: 25px 16px 15px 16px;
    box-shadow: 0px 0px 30px 0px rgba(113, 97, 239, 0.15);
    background: var(--white);
  }

  nav>div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  nav > div> a >img {
    width: 150px;
  }

  /* For Laptop Devices */
  @media screen and (min-width: 768px) {
    nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    nav>div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 15px 160px 25px 160px;
    }
  }
}
</style>
